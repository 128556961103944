// Variables
@import '../app/variables';

header{
	&.landing{
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		.video{
			position: absolute;
			overflow: hidden;
			top: 0;
			margin: auto;
			z-index: 0;
			width: 100%;
			height: 100%;
		}
		.logo,h1,h2,p,a{
			opacity: 0;
		}
		.logo{
			animation: 1s forwards view;
		}
		h1{
			animation: 1s forwards view .5s;
		}
		h2{
			animation: 1s forwards view .75s;
		}
		p{
			animation: 1s forwards view 1s;
		}
		.line {
			border-left: 3px solid $primary;
			animation: 1s forwards line 1.25s;
			height: 0;
			margin: 2vh;
			border-radius: 3px;
		}
		a{
			animation: 1s forwards view 1.75s;
		}
	}
}

@keyframes line {
	100% {
		height: 7vh;
	}
}
@media ($sm) {
	@keyframes line {
		100% {
			height: 3vh;
		}
	}
}

@keyframes view {
	100% {
		opacity: 1;
	}
}

.index{
	.stats{
		.row{
			&>div{
				display: flex;
				align-items: center;
				flex-direction: column;
				.typo{
					font-size: 2em;
				}
			}
		}
	}
	.specs{
		.grid{
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			.carre{
				&:nth-of-type(odd){
					background-color: $secondary;
					color: $primary;
				}
				div{
					padding: 3vw;
				}
			}
		}
	}
}

@media (max-width: 1500px) {
	.carre{
		&:before{
			padding-top: 0%;
		}
		&>*{
			position:  relative;
		}
	}
}

@media ($lg) {
	.index{
		.specs{
			.grid{
				grid-template-columns: 1fr 1fr;
				.carre:nth-child(4){
					background-color: $secondary;
					color: $primary;
				}
				.carre:nth-child(3){
					color: $secondary;
					background-color: $primary;
				}
			}
		}
	}
}

@media ($sm) {
	.index{
		.specs{
			.grid{
				grid-template-columns: 1fr;
				.carre:nth-of-type(odd){
					background-color: $secondary;
					color: $primary;
				}
				.carre:nth-child(4){
					color: $secondary;
					background-color: $primary;
				}
			}
		}
	}
	.carre{
		&:before{
			padding-top: 100%;
		}
		&>*{
			position:  absolute;
		}
		&>div{
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}
	}
}

